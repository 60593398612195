<template>
  <div class="demo">
    <ul class="fp-box">
      <!-- 默认进来是7位，超过自动加 -->
      <li
        ref="li"
        v-for="i in countStr.length > 7 ? countStr.length : 7"
        :key="i"
      >
        <!-- 每列中的10行数字 -->
        <span v-for="num in 10" :key="num">
          <span class="line"></span>
          <!-- {{ num - 1 }} -->
          <span class="num-wrap">{{ num - 1 }}</span>
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    countStr: String,
  },
  data() {
    return {
      numberArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], //固定每列中的19行数字
      numStr: "", // 需要展示的数字字符串
      numArr: [0, 0, 0, 0, 0, 0, 0], //默认展示7个数字数组
    };
  },
  methods: {
    // 初始化数字卡片
    initNumCard() {
      this.numStr = this.countStr;
      this.numArr = this.numStr.split("");
      let numArrlen = this.numArr.length;
      // 展示数字的处理，不够7位前面补0
      // 默认进来是7位，超过自动加
      for (
        let i = 0;
        i < (this.countStr.length > 7 ? this.countStr.length : 7) - numArrlen;
        i++
      ) {
        this.numArr.unshift(0);
      }
      this.$refs.li.forEach((item, index) => {
        let ty = this.numArr[index];
        // 滚动数字(li标签)
        item.style.transform = `translateY(-${ty * 47}px)`;
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
// 这个字体是我自己项目中引进来的字体
.fp-box {
  display: flex;
  overflow: hidden;
  background-color: green;
  li {
    width: 30px;
    height: 47px;
    flex-direction: column;
    transition: transform 1s ease-in-out;
    margin-right: 4px;
    & > span {
      text-align: center;
      background: red;
      font-size: 24px;
      color: blue;
      display: flex;
      display: inline-block;
      width: 30px;
      height: 47px;
      line-height: 47px;
      float: left;
      position: relative;
      font-family: "Orbitron";
      .line {
        display: inline-block;
        height: 1px;
        background: #001347;
        width: 100%;
        position: absolute;
        left: 0;
        top: 23px;
      }
      .num-wrap {
        font-family: "Orbitron";
      }
    }
  }
}
</style>
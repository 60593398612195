<template>
  <div class="home">
    <div class="nav">
      <Header></Header>
      <div class="title">
        {{ title ? title.slice(0, title.length - 6) : "" }}
      </div>
      <div class="title" style="margin-top: 20px">
        {{ title ? title.slice(title.length - 6, title.length) : "" }}
      </div>
      <!-- <img class="title" :src="require('./static/image/title.png')" alt="" /> -->
      <!-- <div class="search">
        <img :src="require('./static/image/search.png')" alt="" />
        <input
          type="text"
          placeholder="请输入关键词"
          placeholder_class="placeholder_class"
        />
        <div class="button">搜索</div>
      </div> -->
      <div class="text">— 主动服务，快速落实 —</div>
    </div>
    <div class="info">
      <div class="notice_div">
        <img :src="require('./static/image/notice.png')" alt="" />
        <!-- <div>转发福州市财政局关于转发福建省财政厅</div> -->
        <div class="notice_all">
          <div
            class="notice"
            :style="[{ top: notice.top, transition: notice.transition }]"
          >
            <div
              v-for="(item, index) in noticeArr"
              :key="index"
              @click="jumpUrl(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <!-- 通知公告 -->
      <div class="announcement1">
        <div class="title">
          <div class="name">通知公告</div>
          <img
            class="icon"
            :src="require('./static/image/announcement.png')"
            alt=""
          />
          <div class="more" @click="toNotice()">
            <div>更多</div>
            <img :src="require('./static/image/more.png')" alt="" />
          </div>
        </div>
        <div class="list">
          <div
            class="list_div"
            v-for="(item, index) in announcement1Arr"
            :key="index"
            @click="toListDetail(item.noticeId, 0, 0)"
          >
            <div>{{ item.noticeTitle }}</div>
            <div>{{ item.createTimeDate }}</div>
          </div>
        </div>
      </div>
      <!-- 自行采购公告 -->
      <div class="announcement2">
        <div class="title">
          <div class="name">自行采购公告</div>
          <img
            class="icon"
            :src="require('./static/image/announcement.png')"
            alt=""
          />
          <div class="more" @click="toPurchase()">
            <div>更多</div>
            <img :src="require('./static/image/more.png')" alt="" />
          </div>
        </div>
        <div class="list">
          <div class="list_left">
            <div
              :class="anmt2Index1 == 0 ? 'active' : ''"
              @click="chanceAnmt2Index1(0)"
            >
              自行采购
            </div>
            <div
              :class="anmt2Index1 == 1 ? 'active' : ''"
              @click="chanceAnmt2Index1(1)"
            >
              招标公告
            </div>
            <div
              :class="anmt2Index1 == 2 ? 'active' : ''"
              @click="chanceAnmt2Index1(2)"
            >
              结果公告
            </div>
            <div
              :class="anmt2Index1 == 3 ? 'active' : ''"
              @click="chanceAnmt2Index1(3)"
            >
              补充公告
            </div>
          </div>
          <div class="list_right">
            <div class="list_right_top">
              <div
                :class="anmt2Index2 == 0 ? 'active' : ''"
                @click="chanceAnmt2Index2(0)"
              >
                省级
                <img
                  v-if="anmt2Index2 == 0"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
              <div
                :class="anmt2Index2 == 1 ? 'active' : ''"
                @click="chanceAnmt2Index2(1)"
              >
                市级
                <img
                  v-if="anmt2Index2 == 1"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
              <div
                :class="anmt2Index2 == 2 ? 'active' : ''"
                @click="chanceAnmt2Index2(2)"
              >
                区县级
                <img
                  v-if="anmt2Index2 == 2"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="list_div">
              <div class="list_tr">
                <div class="list_th">区域</div>
                <div class="list_th">采购单位</div>
                <div class="list_th">项目名称</div>
                <div class="list_th">开标时间</div>
                <div class="list_th">发布时间</div>
              </div>
              <div
                class="list_tr"
                v-for="(item, index) in announcement2Arr"
                :key="index"
                @click="toListDetail(item.noticeId, 1, anmt2Index1)"
              >
                <div class="list_td" v-if="item.projectArea == 0">省级</div>
                <div class="list_td" v-else-if="item.projectArea == 1">
                  市级
                </div>
                <div class="list_td" v-else-if="item.projectArea == 2">
                  区县级
                </div>
                <div class="list_td" v-else></div>
                <Poptip
                  trigger="hover"
                  :content="item.entrustGroup"
                  placement="top"
                  class="list_td"
                >
                  {{ item.entrustGroup }}
                </Poptip>
                <!-- <div class="list_td">{{ item.entrustGroup }}</div> -->
                <Poptip
                  trigger="hover"
                  :content="item.noticeTitle"
                  placement="top"
                  class="list_td"
                >
                  {{ item.noticeTitle }}
                </Poptip>
                <div class="list_td">{{ item.bidStartTime }}</div>
                <div class="list_td">{{ item.createTimeDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 网上竞价平台 -->
      <div class="announcement3">
        <div class="title">
          <div class="name">网上竞价公告</div>
          <img
            class="icon"
            :src="require('./static/image/announcement.png')"
            alt=""
          />
          <div class="more" @click="onlineBidding()">
            <div>更多</div>
            <img :src="require('./static/image/more.png')" alt="" />
          </div>
        </div>
        <div class="list">
          <div class="list_left">
            <div
              :class="anmt3Index1 == 0 ? 'active' : ''"
              @click="chanceAnmt3Index1(0)"
            >
              自行采购
            </div>
            <div
              :class="anmt3Index1 == 1 ? 'active' : ''"
              @click="chanceAnmt3Index1(1)"
            >
              招标公告
            </div>
            <div
              :class="anmt3Index1 == 2 ? 'active' : ''"
              @click="chanceAnmt3Index1(2)"
            >
              结果公告
            </div>
            <div
              :class="anmt3Index1 == 3 ? 'active' : ''"
              @click="chanceAnmt3Index1(3)"
            >
              补充公告
            </div>
          </div>
          <div class="list_right">
            <div class="list_right_top">
              <div
                :class="anmt3Index2 == 0 ? 'active' : ''"
                @click="chanceAnmt3Index2(0)"
              >
                省级
                <img
                  v-if="anmt3Index2 == 0"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
              <div
                :class="anmt3Index2 == 1 ? 'active' : ''"
                @click="chanceAnmt3Index2(1)"
              >
                市级
                <img
                  v-if="anmt3Index2 == 1"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
              <div
                :class="anmt3Index2 == 2 ? 'active' : ''"
                @click="chanceAnmt3Index2(2)"
              >
                区县级
                <img
                  v-if="anmt3Index2 == 2"
                  :src="require('./static/image/line.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="list_div">
              <div class="list_tr">
                <div class="list_th">区域</div>
                <div class="list_th">采购单位</div>
                <div class="list_th">项目名称</div>
                <div class="list_th">开标时间</div>
                <div class="list_th">发布时间</div>
              </div>
              <div
                class="list_tr"
                v-for="(item, index) in announcement3Arr"
                :key="index"
                @click="toListDetail(item.noticeId, 2, anmt3Index1)"
              >
                <div class="list_td" v-if="item.projectArea == 0">省级</div>
                <div class="list_td" v-else-if="item.projectArea == 1">
                  市级
                </div>
                <div class="list_td" v-else-if="item.projectArea == 2">
                  区县级
                </div>
                <div class="list_td" v-else></div>
                <Poptip
                  trigger="hover"
                  :content="item.entrustGroup"
                  placement="top"
                  class="list_td"
                >
                  {{ item.entrustGroup }}
                </Poptip>
                <!-- <div class="list_td">{{ item.entrustGroup }}</div> -->
                <Poptip
                  trigger="hover"
                  :content="item.noticeTitle"
                  placement="top"
                  class="list_td"
                >
                  {{ item.noticeTitle }}
                </Poptip>
                <div class="list_td">{{ item.bidStartTime }}</div>
                <div class="list_td">{{ item.createTimeDate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Fotter></Fotter>
    <!-- <div @click="danyan()">danyan</div>
    <theNumber :countStr="serverCount" ref="numCard1"></theNumber> -->
  </div>
</template>
<script>
import Header from "../../components/common/header.vue";
import Fotter from "../../components/common/fotter.vue";
import theNumber from "../number/index.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import {
  getDynamicNews,
  getNoticeData,
  getNoticeList,
  queryPageInfo,
} from "../../request/home.js";
export default {
  components: { Header, Fotter, theNumber },
  data() {
    return {
      noticeArr: [],
      notice: {
        index: 0,
        top: 0,
        transition: "",
        interval: null,
      },
      announcement1Arr: [],
      anmt2Index1: null,
      anmt2Index2: null,
      announcement2Arr: [],
      anmt3Index1: null,
      anmt3Index2: null,
      announcement3Arr: [],
      serverCount: "000000",
      noticeTitle: "",
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.title.title,
    }),
  },
  created() {
    sessionStorage.setItem("id", "false");
    var id = this.getQueryVariable("id");
    if (id) {
      sessionStorage.setItem("id", id);
    } else {
      sessionStorage.setItem("id", "");
    }
    this.queryPageInfo(id);

    window.addEventListener("hashchange", function () {
      // 监听hash变化，点击浏览器的前进后退会触发
      location.reload();
    });
  },
  mounted() {
    // this.$refs["numCard1"].initNumCard();
    // this.toActions();
    var id = this.getQueryVariable("id");
    // 滚动通知
    this.getDynamicNews(id);
    // 通知公告
    this.getNoticeData();
    // 自行采购公告
    this.getNoticeList();
    // 网上竞价公告
    this.getNoticeListOnce();
    // 滚动通知滑动
    var _this = this;
    _this.noticeScroll();
    window.addEventListener("resize", () => {
      _this.notice.top = -77 * _this.$htmlSize * _this.notice.index + "px";
      _this.noticeScroll();
    });
  },
  methods: {
    noticeTitleBlur(title) {
      console.log(title);
      this.noticeTitle = title;
    },
    ...mapActions({ titleActions: "title/titleActions" }),
    toActions() {
      Promise.all([this.titleActions("toActions")]).then(() => {
        console.log(this);
      });
    },
    // 跳转页面
    jumpUrl(item) {
      location.href = item.url;
    },
    danyan() {
      var number = Number(this.serverCount) + 999;
      this.serverCount = number.toString();
      // this.$refs["numCard1"].initNumCard();
    },
    // 滚动通知滑动
    noticeScroll() {
      var _this = this;
      clearInterval(_this.notice.interval);
      _this.notice.interval = setInterval(() => {
        if (_this.notice.index >= _this.noticeArr.length) {
          _this.notice.index = 0;
          _this.notice.transition = "none";
          _this.notice.top = 0;
        } else {
          _this.notice.transition = "0.3s all";
          _this.notice.top = -77 * _this.$htmlSize * _this.notice.index + "px";
        }
        _this.notice.index++;
      }, 2000);
    },
    chanceAnmt2Index1(index) {
      this.anmt2Index1 = index;
      this.getNoticeList();
    },
    chanceAnmt2Index2(index) {
      this.anmt2Index2 = index;
      this.getNoticeList();
    },
    chanceAnmt3Index1(index) {
      this.anmt3Index1 = index;
      this.getNoticeListOnce();
    },
    chanceAnmt3Index2(index) {
      this.anmt3Index2 = index;
      this.getNoticeListOnce();
    },
    toNotice() {
      this.$router.push({
        path: "/notice",
      });
    },
    toPurchase() {
      this.$router.push({
        path: "/to_purchase",
      });
    },
    onlineBidding() {
      this.$router.push({
        path: "/online_bidding",
      });
    },
    toListDetail(id, type1, type2) {
      console.log(type2);
      this.$router.push({
        path: "/list_detail",
        query: { noticeId: id, type1: type1, type2: type2 },
      });
    },
    // 滚动通知
    getDynamicNews(id) {
      getDynamicNews({
        id: id,
      }).then((res) => {
        this.noticeArr = res.data;
      });
    },
    // 通知公告
    getNoticeData() {
      getNoticeData({
        current: 1,
        size: 4,
        noticeType: 3,
      }).then((res) => {
        this.announcement1Arr = res.data.records;
      });
    },
    // 自行采购公告
    getNoticeList() {
      getNoticeList({
        // 自行采购 0  招标公告 1  结果公告 2   补充公告 3
        noticeType: this.anmt2Index1,
        //  省级 0 市 1 区 2
        projectArea: this.anmt2Index2,
        current: 1,
        size: 5,
      }).then((res) => {
        this.announcement2Arr = res.data.records;
      });
    },
    // 网上竞价公告
    getNoticeListOnce() {
      getNoticeList({
        // 自行采购 0  招标公告 1  结果公告 2   补充公告 3
        noticeType: this.anmt3Index1,
        //  省级 0 市 1 区 2
        projectArea: this.anmt3Index2,
        current: 1,
        size: 5,
      }).then((res) => {
        this.announcement3Arr = res.data.records;
      });
    },
    // 获取url参数
    getQueryVariable(variable) {
      // hash模式
      var query = window.location.hash.substring(3);
      // history模式 获取search
      // var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return;
    },
    queryPageInfo(id) {
      queryPageInfo({ id: id }).then((res) => {
        this.$store.dispatch("title/titleActions", res.data.plateform);
        this.$store.dispatch("title/loginUrlActions", res.data.loginUrl);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.home {
  // width: 100vw;
  width: 100%;
  min-height: 100vh;
  background: #f2f3f7;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;
  .nav {
    width: 100%;
    height: 498px;
    background-image: url("./static/image/nav.png");
    background-size: 100% 100%;
    box-sizing: border-box;

    .title {
      // display: block;
      // width: 446px;
      width: 100%;
      height: 45px;
      margin: 0 auto;
      margin-top: 97px;
      .center();
      font-size: 48px;
      color: white;
      font-weight: bold;
    }
    .search {
      width: 615px;
      height: 66px;
      background: #ffffff;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      margin: 0 auto;
      margin-top: 51px;
      position: relative;

      img {
        width: 22px;
        height: 22px;
        margin-right: 16px;
      }
      input {
        outline: none;
        border: none;
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
      }
      /deep/ .placeholder_class {
        color: #b7bcce;
      }
      .button {
        width: 127px;
        height: 50px;
        background: #2957ff;
        font-size: 16px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        .center();
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .text {
      text-align: center;
      height: 28px;
      line-height: 28px;
      font-size: 20px;
      font-family: PingFang-SC-Regular, PingFang-SC;
      font-weight: 400;
      color: #ffffff;
      margin-top: 42px;
    }
  }
  .info {
    box-sizing: border-box;
    padding: 24px 360px;

    .notice_div {
      width: 100%;
      height: 77px;
      background: #ffffff;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      box-sizing: border-box;
      padding-left: 24px;
      img {
        width: 37px;
        height: 37px;
        margin-right: 16px;
      }
      .notice_all {
        flex: 1;
        height: 77px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #696d7d;
        overflow: hidden;
        position: relative;

        .notice {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          transition: 0.3s all;
          cursor: pointer;

          div {
            width: 100%;
            height: 77px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }
    .announcement1,
    .announcement2,
    .announcement3 {
      box-sizing: border-box;
      padding: 0 24px;
      margin-top: 24px;
      background: #ffffff;
      padding-bottom: 8px;
      .title {
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .name {
          font-size: 23px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
          margin-right: 8px;
        }
        .icon {
          width: 51px;
          height: 16px;
        }
        .more {
          .center();
          font-size: 16px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #2957ff;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          img {
            width: 14px;
            height: 22px;
          }
        }
      }
    }
    .announcement1 {
      .list {
        .list_div {
          height: 53px;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          border-top: 1px dashed #c7ccdd;
          cursor: pointer;

          div:nth-child(1) {
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #232428;
          }
          div:nth-child(2) {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #696d7d;
          }
        }
      }
    }
    .announcement2,
    .announcement3 {
      .list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        .list_left {
          width: 128px;
          margin-right: 24px;

          div {
            width: 128px;
            height: 36px;
            background: #f2f4fc;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #696d7d;
            margin-bottom: 12px;
            cursor: pointer;
            .center();
          }
          .active {
            background: #2957ff;
            color: #ffffff;
          }
        }
        .list_right {
          flex: 1;
          .list_right_top {
            width: 252px;
            height: 36px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            div {
              flex: 1;
              height: 36px;
              background: #f2f4fc;
              font-size: 14px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #696d7d;
              position: relative;
              cursor: pointer;
              .center();
              img {
                width: 8px;
                height: 4px;
                position: absolute;
                bottom: 3px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            .active {
              color: #2957ff;
              box-sizing: border-box;
              border: 1px solid #2957ff;
            }
          }
          .list_div {
            .list_tr {
              height: 54px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              text-align: left;
              border-top: 1px dashed #c7ccdd;
              cursor: pointer;

              .list_th {
                font-size: 14px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: #232428;
              }
              // .list_td_position{
              //   position: relative;
              //   z-index: 999999;
              // }
              // .list_td_position_top{
              //   position: absolute;
              //   bottom: 30px;
              //   left: 20px;
              //   width: 100px;
              //   height: 100px;
              //   z-index: 999999;
              // }
              .list_td {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #232428;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              /deep/ .ivu-poptip-rel {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              // .list_td_td{
              //   width: 100%;
              //   overflow: hidden;
              //   white-space: nowrap;
              //   text-overflow: ellipsis;
              // }
              .list_th:nth-child(1),
              .list_td:nth-child(1) {
                flex: 0.5;
              }
              .list_th:nth-child(2),
              .list_td:nth-child(2) {
                flex: 1.5;
              }
              .list_th:nth-child(3),
              .list_td:nth-child(3) {
                flex: 3;
              }
              .list_th:nth-child(4),
              .list_td:nth-child(4) {
                flex: 1.2;
                margin-left: 20px;
              }
              .list_th:nth-child(5),
              .list_td:nth-child(5) {
                flex: 1.5;
              }
              .list_td:nth-child(4) {
                color: #f49716;
              }
            }
            .list_tr:nth-child(1) {
              height: 60px;
              border: none;
              cursor: default;
            }
          }
        }
      }
    }
  }
}
</style>
